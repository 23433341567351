.colorSchemeForm {
}

.tableHeader {
  display: flex;
  background: #777;
}

.tableBody {
  display: flex;
  background: white;
}

.tableChild {
  min-width: 200px;
  width: 35%;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  /* height: 50px; */
  /* line-height: 25px; */
  /* vertical-align: middle; */
  text-align: center;
  display: flex;
}

.tableChildText {
  min-width: 140px;
  width: 15%;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  /* height: 50px; */
  /* line-height: 25px; */
  /* vertical-align: middle; */
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.tableChildEdit {
  min-width: 200px;
  width: 15%;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  /* height: 50px; */
  /* line-height: 25px; */
  /* vertical-align: middle; */
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.tableChildEdit label {
  display: flex;
  align-items: center;
}
