.Branding {
  height: 100%;
}

.brandingCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.gridContainer {
  flex-grow: 1;
  position: relative;
}

.companylogo {
  padding-left:20px;
  display: block;
  max-width: 300px;
}

.dictionary {
  padding-left:20px;
}

.communityLogo {
  display: flex;
}

.communityLogo div {
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  width: 33.33%;
}

.communityLogoTable {
  border: 1px solid #777;
}

.communityLogoTable header {
  background: #777;
  display: flex;
}

.communityLogoTable header div {
  overflow: hidden;
  padding: 0.5rem 1rem;
  width: 33.33%;
}

.customPagesTable {
  border: 1px solid #777;
}

.customPagesTable header {
  background: #777;
  display: flex;
}

.customPagesTable header div {
  overflow: hidden;
  padding: 0.5rem 1rem;
  width: 33.33%;
}

.customPagesTable header div:last-child {
  width: calc(33.33% - 100px);
}
.imagePreview {
  max-width: 200px;
}
.linkItem {
  display: flex;
}

.linkItem div {
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  width: 33.33%;
}
.linkItem div:nth-of-type(3) {
  width: calc(33.33% - 100px);
}

.linkItem button {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  width: 50px;
}

.linkItem button a {
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-bottom: 0;
  position: absolute;
  width: 100%;
}

.linkItem button svg {
  font-size: 1.2rem;
}
