.logoWrapper {
  width: 120px;
  height: 75px;
  left: 42.71px;
  top: 32px;
}
.logo {
  background-color: white;
  height: 100%;
  width: 100%;
  object-fit: contain;
}