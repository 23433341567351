.LoadingIndicator {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.LoadingIndicator > div:first-of-type {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 1rem;
}

.LoadingIndicator > div:first-of-type > div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.LoadingIndicator > div:first-of-type > div:nth-child(1) {
  animation-delay: -0.45s;
}
.LoadingIndicator > div:first-of-type > div:nth-child(2) {
  animation-delay: -0.3s;
}
.LoadingIndicator > div:first-of-type > div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
