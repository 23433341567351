.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  padding:20px;
  object-fit: contain;
  max-height: 100vh;
  max-width: 100%;
}
