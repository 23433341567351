.Models {
  height: 100%;
}

.modelCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.gridContainer {
  flex-grow: 1;
  position: relative;
}
