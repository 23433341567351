.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1.5rem 3rem;
  background-color: white;
}

.container h1 {
  margin-bottom: 1.5rem;
}

.container form {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
}

.container label {
  display: block;
  margin-bottom: 1rem;
}

.container input {
  display: block;
  margin-top: 0.5rem;
}

.container input[type=text] {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.container textarea {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  display: block;
  margin-top: 0.5rem;
}

.container button {
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.container button:disabled {
  cursor: not-allowed;
}
