.container {

}
.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: space-evenly;
  height: 72px;
  text-decoration: none;
}

.active .navLink {
  color: white;
}

.inactive .navLink {
  color: white;
  opacity: 0.7;
}

.routeItemInactive Link:visited {
  color: #fff;
}
