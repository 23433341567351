.Search {
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  position: relative;
  min-width: 300px;
  width:50%
}

.searchIconWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
